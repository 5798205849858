/**
 * = PRINT styles
 *
 * - Taken straight from: https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L197
 *
 * ========================================================================= */

/* stylelint-disable declaration-no-important  */

@page {
  size: 301mm 425.7mm;
  margin: 15mm;
  mso-header: header;
  mso-footer: footer;
  //   margin-top: 0;
  //   margin-bottom: 0;
  //   mso-header: header;
  //   mso-footer: footer;
}

@media print {
  *,
  *:before,
  *:after,
  *:first-letter,
  p:first-line,
  div:first-line,
  blockquote:first-line,
  li:first-line {
    background: transparent !important;
    color: #000 !important; /* Black prints faster:
								   http://www.sanbeiji.com/archives/953 */
    box-shadow: none !important;
    text-shadow: none !important;
  }

  //   body {
  //     padding-top: 15mm;
  //     padding-bottom: 15mm;
  //     font-size: 0.7rem;
  //     width: 297mm;
  //   }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
    margin-left: 10px;
  }

  abbr[title]:after {
    content: ' (' attr(title) ')';
  }

  /*
	 * Don't show links that are fragment identifiers,
	 * or use the `javascript:` pseudo protocol
	 */

  a[href^='#']:after,
  a[href^='javascript:']:after {
    content: '';
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  /*
	 * Printing Tables:
	 * http://css-discuss.incutio.com/wiki/Printing_Tables
	 */

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .c-header {
    position: relative !important;
    border-bottom: 1px solid var(--color--grey--light);
    a[href]:after {
      content: none;
    }
  }

  a[href] {
    &.c-logo,
    &.c-link--cover {
      position: relative;
      top: 0 !important;
      left: 0 !important;
      &:after {
        content: none;
      }
    }
  }
  .has--a,
  .c-meta__author,
  .c-meta__link,
  .c-meta__cats,
  p {
    a[href] {
      text-decoration: none;
      &:after {
        content: none;
      }
    }
  }

  /** HIDE certain elements completely **/

  aside,
  figure,
  form,
  iframe,
  #mobile-menu-modal,
  .c-alert,
  .c-article .c-figure__hold,
  .c-btn,
  .c-btn--download,
  .c-btn--header,
  .c-btns,
  .c-card__media,
  .c-carousel--menu,
  .c-dropdown,
  .c-footer--primary,
  .c-footer--secondary ul,
  .c-footer-social,
  .c-form,
  .c-hero__content__item--right,
  .c-linkbar,
  .c-links,
  .c-pagination__hold,
  .c-quicklinks,
  .c-search__overlay,
  .c-tab__media__hold,
  .c-table img,
  .c-tools-menu,
  .c-top-filters,
  .u-image,
  .u-print-none,// class to be added to any components/elements when we want to exclude them from printing
  .footer-button,
  .site-navigation,
  .tablist {
    display: none !important;
  }

  .c-ada-accordion {
    &--panel {
      display: block !important;
    }
    .icon {
      display: none !important;
    }
  }

  .c-article[data-overlayer] {
    height: 100%;
    .c-figure__hold {
      display: none;
      + div {
        display: none;
      }
    }

    .c-overlayer {
      position: relative;
      display: block;
      min-height: 100%;
      break-inside: avoid;
      &__content {
        height: 100%;
        max-width: none;
        padding: 0;
        border: none;
      }
    }
  }

  .c-article__listing {
    .c-article {
      &--medium {
        margin-bottom: 0;
      }
      &--big,
      &--medium,
      &--small {
        .c-article__copy {
          padding-top: 0;
        }
      }
      .c-label {
        position: relative;
        top: 0;
        left: 0;
        padding-inline: 0;
        transform: none;
      }
    }
  }

  .c-article__meta--bg {
    padding-left: 0;
  }

  .c-breadcrumb {
    .c-toolbar {
      display: none !important;
    }
    a[href]:after {
      content: none;
    }
  }

  a.c-card {
    text-decoration: none;
    .c-card__body {
      border-top: 0;
    }
    .c-card__media {
      display: none;
    }
    .c-card__text {
      position: relative;
      top: auto;
    }
    &[href]:after {
      content: none;
    }
  }

  .c-distributors {
    &__item {
      .row {
        .col.xs12.ml6.xl4,
        .col.xs12.ml6.xl5 {
          width: 50% !important;
        }
      }
    }
  }

  .c-footer {
    padding-top: 24px;
    border-bottom: none;
    .c-logo {
      margin-top: 0;
    }
  }

  .c-footer--secondary {
    padding-right: 0;
    .row {
      div {
        width: auto !important;
        &:nth-child(2) {
          float: right;
        }
      }
    }
  }

  .c-header {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
  }

  .c-hero {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    &[data-component__theme='dark'] * {
      color: #000 !important;
    }
    &[data-article-hero__size='mini'] {
      min-height: auto;
    }
    &[data-article-hero__layout='2cols'] .c-hero__content__item {
      width: 100%;
    }
    .c-hero__subtext img {
      display: none;
    }
    &__logo {
      img {
        filter: invert(100%);
      }
    }
  }

  .c-hero__media {
    picture {
      display: none;
    }
  }

  .has--alert .c-hero[data-article-hero__size='large'] {
    min-height: 300px !important;
  }

  .c-label {
    background-color: #fff !important;
  }

  .c-media--50-50 div {
    &:first-child {
      display: none !important;
    }
    &:last-child {
      width: 100% !important;
    }
  }

  .c-multicolumn-text--border-primary .c-multicolumn-text__item {
    border: none;
  }

  .c-multicolumn-text--border-l .c-multicolumn-text__item {
    padding: 0;
  }

  .c-multicolumn-text__item {
    padding: 0;
    &.u-bg-color--primary--lightest {
      border: none;
    }
  }

  .c-tabs {
    .tab {
      display: none !important;
    }
    .tabpanel {
      .tabpanel__container {
        display: block !important;
      }
    }
    .c-btn {
      display: none !important;
    }
  }

  .tabpanel {
    display: block !important;
  }

  .c-tab--fb .c-tab__copy__hold {
    padding: 0 !important;
  }

  .c-widget__header header {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--color--grey--light);
  }

  .o-wrapper {
    .u-bg-color--grey--lighter,
    .u-bg-color--primary,
    .u-bg-color--primary--lightest,
    .u-bg-color--text {
      padding: 0;
      background-color: transparent !important;
      &::before,
      &::after {
        background-color: transparent !important;
      }
    }
    &.u-bg-color--text :not(input) {
      color: #000 !important;
    }
  }

  .o-wrapper--has-bg:not(.u-bg-color--stretch) {
    padding: 0 !important;
  }

  .o-wrapper.u-pblock--16--m {
    padding-block: 0;
  }

  [data-component__theme='dark'] {
    .u-image__content {
      p {
        color: #000 !important;
      }
    }
  }
}
